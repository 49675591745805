import React, { useEffect, useRef, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useFetch from '../../../hooks/useFetch';

import Word from '../../../assets/file_types/docx.svg';
import Outlook from '../../../assets/file_types/outlook.svg';
import PDF from '../../../assets/file_types/pdf.png';
import Powerpoint from '../../../assets/file_types/ptt.svg';
import Excel from '../../../assets/file_types/xlsx.svg';
import ZIP from '../../../assets/file_types/zip.svg';
import Loader from '../Loader';
import { TextTooltip } from '../Tooltip';
import Button from './Button';
import TextInput from './TextInput';
import {
    faDownload,
    faEye,
    faFileAlt,
    faTimes,
    faUpload
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/input/FileUpload.module.scss';

const imageFormats = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp'
];

const getFileIcon = (file) => {
    if (!file?.name && !file?.fileName) return;
    let splitName = file.name ? file.name.split('.') : file.fileName.split('.');
    let extension = splitName[splitName.length - 1];

    switch (extension) {
        case imageFormats.includes(extension):
            try {
                return (
                    <img
                        src={file.url ? file.url : URL.createObjectURL(file)}
                        alt={file.name ? file.name : file.fileName}
                        className={styles.image}
                    />
                );
            } catch {
                return (
                    <FontAwesomeIcon icon={faFileAlt} className={styles.icon} />
                );
            }
        case 'xlsx':
        case 'xls':
        case 'csv':
            return (
                <img
                    src={Excel}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="Excel"
                />
            );
        case 'docx':
        case 'docm':
        case 'doc':
            return (
                <img
                    src={Word}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="Word"
                />
            );
        case 'pst':
        case 'msg':
        case 'eml':
            return (
                <img
                    src={Outlook}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="Outlook"
                />
            );
        case 'pttx':
        case 'ptt':
        case 'pttm':
            return (
                <img
                    src={Powerpoint}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="Powerpoint"
                />
            );
        case 'pdf':
            return (
                <img
                    src={PDF}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="PDF"
                />
            );
        case 'zip':
            return (
                <img
                    src={ZIP}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="zip"
                />
            );
        default:
            return <FontAwesomeIcon icon={faFileAlt} className={styles.icon} />;
    }
};

const FileUpload = ({
    thumbnail = true,
    files = [],
    handleRemove,
    handleUpload,
    handleChange,
    width = '400px',
    uploadLimit,
    readOnly = false,
    downloadAll = false,
    downloadSingle = false,
    emptyMessage = 'No files uploaded.',
    showDescription,
    fileRenderer
}) => {
    let fileUploadRef = useRef();

    const [{ loading }, downloadAttachments] = useFetch('', 'GET', {
        manual: true
    });

    const { enqueueSnackbar } = useSnackbar();

    const [drag, setDrag] = useState(false);
    const [dropDepth, setDropDepth] = useState(0);

    const onUpload = (e) => {
        if (
            e?.target?.files &&
            uploadLimit &&
            e?.target?.files?.length > uploadLimit
        ) {
            return enqueueSnackbar(
                `The upload limit is ${uploadLimit} file(s).`,
                {
                    variant: 'error',
                    autoHideDuration: 3000
                }
            );
        }
        handleUpload?.(
            Object.values(e.target.files).map((file) => ({
                file: file,
                fileName: file.name,
                shortName: file.name.split('.')[0],
                extension: file.name.split('.')[1],
                contentType: file.type,
                description: '',
                id: uniqueId(`upload_`)
            }))
        );
        handleChange?.([
            ...files,
            ...Object.values(e.target.files).map((file) => ({
                file: file,
                fileName: file.name,
                shortName: file.name.split('.')[0],
                extension: file.name.split('.')[1],
                contentType: file.type,
                description: '',
                id: uniqueId(`upload_`)
            }))
        ]);
    };

    const onRemove = (id) => {
        handleRemove?.(id);
        handleChange?.(files.filter((file) => file.id !== id));
    };

    const onDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDropDepth(dropDepth + 1);
    };
    const onDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDropDepth(dropDepth - 1);
    };
    const onDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();

        e.dataTransfer.dropEffect = 'copy';
        setDrag(true);
    };

    const onDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let droppedFiles = [...e.dataTransfer.files];

        if (
            droppedFiles &&
            uploadLimit &&
            droppedFiles.length + files?.length > uploadLimit
        ) {
            return enqueueSnackbar(
                `The upload limit is ${uploadLimit} file(s).`,
                {
                    variant: 'error',
                    autoHideDuration: 3000
                }
            );
        }
        if (droppedFiles && droppedFiles.length > 0) {
            handleUpload?.(
                droppedFiles.map((file) => ({
                    file: file,
                    fileName: file.name,
                    shortName: file.name.split('.')[0],
                    extension: file.name.split('.')[1],
                    contentType: file.type,
                    description: '',
                    id: uniqueId('upload_')
                }))
            );
            handleChange?.([
                ...files,
                ...droppedFiles.map((file) => ({
                    file: file,
                    fileName: file.name,
                    shortName: file.name.split('.')[0],
                    extension: file.name.split('.')[1],
                    contentType: file.type,
                    description: '',
                    id: uniqueId('upload_')
                }))
            ]);
            e.dataTransfer.clearData();
            setDropDepth(0);
            setDrag(false);
        }
    };

    useEffect(() => {
        if (dropDepth === 0) setDrag(false);
    }, [dropDepth]);

    const handleDownloadAll = () => {
        downloadAttachments({
            url: downloadAll
        })
            .then((res) => res.blob())
            .then((data) => {
                var objectURL = URL.createObjectURL(data);
                let element = document.createElement('a');
                element.href = objectURL;
                element.download = 'attachments.zip';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                URL.revokeObjectURL(objectURL);
            })
            .catch((err) => console.log(err));
    };

    const handleFileChange = (id, description) => {
        handleChange(
            files.map((file) => ({
                ...file,
                description: file.id === id ? description : file.description
            }))
        );
    };

    return (
        <div className={styles.container} style={{ width }}>
            {files.length === 0 && (
                <div className={styles.noFilesMessage}>{emptyMessage}</div>
            )}
            <div
                className={styles.fileContent}
                /* style={{
                        maxHeight: overflow ? null : '102px',
                        overflow: overflow ? 'visible' : null
                    }} */
            >
                {files.map((file, index) => (
                    <File
                        file={file}
                        key={file.id}
                        id={file.id}
                        thumbnail={thumbnail}
                        onRemove={onRemove}
                        index={index}
                        readOnly={file.readOnly ?? readOnly}
                        download={downloadSingle}
                        showDescription={showDescription}
                        onChange={handleFileChange}
                        fileRenderer={fileRenderer}
                    />
                ))}
            </div>
            <div className={styles.fileActions}>
                {!readOnly && (!uploadLimit || files.length < uploadLimit) && (
                    <div
                        className={styles.fileUpload}
                        onDrop={onDrop}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDragOver={onDragOver}
                        style={{
                            border: drag
                                ? '1px solid var(--secondary-theme-color-light)'
                                : null
                        }}
                    >
                        <input
                            type="file"
                            multiple={!uploadLimit || uploadLimit > 1}
                            name="file-upload"
                            onChange={onUpload}
                            style={{ display: 'none', opacity: 0 }}
                            ref={fileUploadRef}
                        />
                        <Button
                            type="secondary"
                            variant="border"
                            onClick={(e) => {
                                fileUploadRef?.current?.click();
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            className={styles.button}
                            label="Upload Files"
                            icon={faUpload}
                        />
                        <div>or Drop Files</div>
                    </div>
                )}
                {uploadLimit && (
                    <div className={styles.maximumFilesMessage}>
                        Maximum file count:{' '}
                        <p className={styles.maximumFiles}>{uploadLimit}</p>
                    </div>
                )}
                {downloadAll && files && files.length !== 0 && (
                    <Button
                        type="secondary"
                        variant="border"
                        label="Download All"
                        icon={faDownload}
                        className={styles.downloadAll}
                        onClick={handleDownloadAll}
                        loading={loading}
                    />
                )}
            </div>
        </div>
    );
};

FileUpload.propTypes = {
    thumbnail: PropTypes.bool,
    files: PropTypes.array,
    onUpload: PropTypes.func,
    uploadLimit: PropTypes.number,
    replaceOnUpload: PropTypes.bool,
    onRemove: PropTypes.func,
    showDescription: PropTypes.bool
};

const File = ({
    file,
    id,
    thumbnail = true,
    thumbnailWidth = '30px',
    onRemove,
    onChange,
    readOnly,
    download,
    preview = true,
    showDescription = false,
    fileRenderer
}) => {
    const [{ loading: loadingDownload }, downloadAttachment] = useFetch(
        '',
        'GET',
        { manual: true }
    );
    const [{ loading: loadingPreview }, previewAttachment] = useApi('', 'GET', {
        manual: true
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleDownload = () => {
        downloadAttachment({
            url: file.download
        })
            .then((res) => res.blob())
            .then((data) => {
                var objectURL = URL.createObjectURL(data);
                let element = document.createElement('a');
                element.href = objectURL;
                element.target = '_blank';
                element.download = file.fileName;
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                URL.revokeObjectURL(objectURL);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    'Something went wrong when downloading the file.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000,
                        preventDuplicate: true
                    }
                );
            });
    };

    const handlePreview = () => {
        previewAttachment({
            url: file.preview
        })
            .then((attachmentUrl) => {
                window.open(attachmentUrl, '_blank');
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    'Could not preview this file. Please try again.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    const handleDescription = (e) => {
        onChange(file.id, e.target.value);
    };

    //TODO: make this better
    const name = file.name
        ? file.name
        : file.filename
        ? file.filename
        : file.fileName;
    const isPreviewable = [...imageFormats, 'pdf'].includes(
        name?.split?.(/(.*)\.(.*)/)[2]
    );

    return (
        <div className={styles.fileContainer}>
            {thumbnail && (
                <div
                    className={styles.thumbnail}
                    style={{
                        flexBasis: thumbnailWidth,
                        maxWidth: thumbnailWidth,
                        minWidth: thumbnailWidth
                    }}
                >
                    {getFileIcon(file)}
                </div>
            )}
            <div className={styles.fileDetails}>
                <div className={styles.upperContainer}>
                    <div className={styles.name}>
                        <TextTooltip
                            tooltip={file.filename ?? file.fileName}
                            hoverTrigger="overflow"
                            hoverDelay={600}
                        >
                            {file.filename ?? file.fileName}
                        </TextTooltip>
                    </div>
                </div>
                {showDescription && !readOnly && (
                    <div className={styles.lowerContainer}>
                        <TextInput
                            lines={2}
                            value={file.description}
                            placeholder="Description"
                            onChange={handleDescription}
                        />
                    </div>
                )}
                {fileRenderer && fileRenderer(file)}
            </div>
            <div className={styles.fileActions}>
                {file?.preview &&
                    preview &&
                    isPreviewable &&
                    (loadingPreview ? (
                        <div className={styles.fileDownload}>
                            <Loader spinColor="var(--secondary-theme-color-light" />
                        </div>
                    ) : (
                        <div
                            className={styles.fileDownload}
                            onClick={handlePreview}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </div>
                    ))}
                {file.download &&
                    download &&
                    (loadingDownload ? (
                        <div className={styles.fileDownload}>
                            <Loader spinColor="var(--secondary-theme-color-light" />
                        </div>
                    ) : (
                        <div
                            className={styles.fileDownload}
                            onClick={handleDownload}
                        >
                            <FontAwesomeIcon icon={faDownload} />
                        </div>
                    ))}
                {!readOnly && (
                    <TextTooltip
                        tooltip="Remove File"
                        hoverTrigger="always"
                        hoverDelay={1000}
                    >
                        <div
                            className={styles.fileRemove}
                            onClick={onRemove.bind(this, id)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </TextTooltip>
                )}
            </div>
        </div>
    );
};

File.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    thumbnail: PropTypes.bool,
    thumbnailWidth: PropTypes.string,
    onRemove: PropTypes.func,
    index: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default FileUpload;
